import { window } from '../../globals';
import ErrorLogger from '../../error-logger/ErrorLogger';

const loadOptimizelyFxService = async () => import('./OptimizelyFxService');

const experimentFlag = window?.siteMetadata?.experimentFlag || window?.CHECKOUT_STATE?.contextData?.experimentFlag;
const experimentVariation = window?.siteMetadata?.experimentVariation || window?.CHECKOUT_STATE?.contextData?.experimentVariation;

if (experimentFlag && experimentVariation && navigator.cookieEnabled) {
    loadOptimizelyFxService().then(((optimizelyFxService) => {
        const { default: OptimizelyFx } = optimizelyFxService;
        const { optimizely, user } = OptimizelyFx.createContext();
        OptimizelyFx.initDataFile(optimizely).then(() => {
            OptimizelyFx.activateExperiment(user, experimentFlag, experimentVariation);
            OptimizelyFx.addClarityVariable(experimentFlag, experimentVariation);
        }).catch((e: Error) => {
            ErrorLogger.createFromGlobals()?.log(e);
        });
    })).catch((e: Error) => {
        ErrorLogger.createFromGlobals()?.log(e);
    });
}
